'use client';

import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { LogoIcon, LogoWithoutTextIcon } from './icons';
import { ChevronLeft } from './icons/chevron';

const MinimalNav = () => {
  const searchParams = useSearchParams();
  const getBackPath = () => {
    const redirect = searchParams?.get('redirect');
    return redirect ? redirect : '/';
  };
  return (
    <div className="w-full">
      {/* mobile */}
      <div className="lg:hidden">
        <div className="bg-white-500 relative flex h-[6.8rem] w-full items-center px-[2.4rem]">
          <Link
            href={'/'}
            className="flex w-full items-center justify-center text-brand-white"
          >
            <LogoWithoutTextIcon width={100} height={100} />
          </Link>
        </div>
      </div>
      {/* desktop */}
    </div>
  );
};

export default MinimalNav;
